/* the word animation container */
.cycling-word-container {
  display: flex;
  //align-content: center;
  margin-top: 10em;
  flex-direction: column;
}

.i-am {
  //align-self: center;
}

.cycling-word {
  color: #8685ef;
  position: relative;
  width: 100%;
}

.cycling-word span {
  width: 100%;
  position: absolute;
  overflow: hidden;
  right: 0%;
  animation: animate 10s linear infinite;
  opacity: 0;
}

.cycling-word span:nth-child(1) {
  animation-delay: 0s;
}

.cycling-word span:nth-child(2) {
  animation-delay: 2s;
}

.cycling-word span:nth-child(3) {
  animation-delay: 4s;
}

.cycling-word span:nth-child(4) {
  animation-delay: 6s;
}

.cycling-word span:nth-child(5) {
  animation-delay: 8s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  2% {
    opacity: 1;
    transform: translateX(0px);
  }
  18% {
    opacity: 1;
    transform: translateX(0px);
  }
  20% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}
