//fonts
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fauna+One&display=swap');

$serif-font: 'Playfair Display', serif;
$sans-serif-font: 'Fauna One', sans-serif;

//colors
$black: #333;
$blue: #8685ef;
$grey: #aca9bb;
