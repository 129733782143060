@import 'variables';

.project-container {
  margin-top: 6em;
}

.project-container:last-child {
  margin-bottom: 3em;
}

.project-item {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-content: center;

  img {
    margin: 1.5em 0;
    max-width: 100%;
    width: 100%;
    border: 1px solid $grey;
  }

  .about-text {
    margin-top: 0 !important;
    margin-bottom: 1.5em;
  }
}

.project-links {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.button {
  cursor: pointer;
  font-weight: bold;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  width: 40%;
  margin: 0 auto;
}

.project-button {
  color: $blue;
  border: 2px solid $blue;
}

.project-button-alt {
  color: #fff;
  border: 2px solid $blue;
  background-color: $blue;
}

// .project-image {
//   background-repeat: no-repeat;
//   background-size: 100%;
//   background-position: center;
//   width: 100%;
//   height: 200px;
// }
